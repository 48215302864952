@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+HK&display=swap");
/* @import "@glidejs/glide/src/assets/sass/glide.core.scss";
@import "@glidejs/glide/src/assets/sass/glide.theme.scss"; */
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans HK", sans-serif;
  font-size: 16px;
}
/* https://mui.com/material-ui/customization/z-index/ */
.swal2-container {
  /* looks production env will overwrite this style, so, add important */
  z-index: 1501 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}
